import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';
import Button from '@material-ui/core/Button';
import LogoHorizontal from '../assets/logoHorizontal.png';

import 'bootstrap/dist/css/bootstrap.css';

const NavBarTemp = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div style={{width: '100%'}}>
            <Navbar style={{ backgroundColor: '#000' }} color="black" light expand="md">
                <NavbarBrand style={{ color: '#cdcdcd', fontWeight: 'bold', width: 'auto' }}><img style={{ marginLeft: 20 }} width="200" height="55" src={LogoHorizontal} /></NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav style={{width: '55%'}} className="mr-auto" navbar>

                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}
//<NavbarText style={{ cursor: 'pointer', marginRight: 6 }}><input className="searchbar" type="text" placeholder="Search"></input><FaSearch style={{ marginLeft: 25 }} className="nav-button" size="19" /></NavbarText>

export default NavBarTemp;