import React, { Component, useState, useRef, useMemo } from 'react';
import NavBar from './components/navbar';
import NavBarTemp from './components/navbar_temp';
// import * as THREE from 'three/src/Three';
// import { Canvas, useFrame } from 'react-three-fiber';
// import { apply as applySpring, useSpring, a, interpolate } from '@react-spring/three';
import Home from './components/home';
import HomeTemp from './components/home_temp';
import axios from "axios";

import './App.css';

class App extends Component {

  constructor() {
    super();
    this.state = {
      width: 0,
      height: 0,
      first_name: '',
      last_name: '',
      company: '',
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  requestSubmit = async () => {
    if (!this.state.first_name || !this.state.email) {
      alert('Please enter a valid name and email address');
    } else {
      const name = {
        first: this.state.first_name,
        last: this.state.last_name
      }
      const set = {
        name: name,
        company: this.state.company,
        email: this.state.email
      }
      var log_status = await axios.post(`https://solarsystem.app/demo/request`, set)
        .then(res => {
          return res.data
        })
        .catch(err => {
          return err.response.data
        });

      alert(log_status);
      this.setState({
        first: '',
        last: '',
        company: '',
        email: ''
      })
    }
  }

  emailValidate = (e) => {
    if (e === false && this.state.email) {
      alert('Please enter a valid email address')
    }
  }

  // requestLogin = async () => {
  //   axios.get(`http://localhost:5000/app`);
  //   //var log_status = await this.props.requestDemo(set);
  //   alert(log_status)
  // }

  render() {

    return (
      <div style={{ width: this.state.width, height: this.state.height, backgroundColor: '#000' }}>
        <NavBarTemp />
        {/* <HomeTemp {...this.state} /> */}
        <Home handleChange={this.handleChange} requestSubmit={this.requestSubmit} emailValidate={this.emailValidate} {...this.state} />
      </div >
    );
  }
}

export default App;
