import React, { useEffect, useState } from "react";
import { FaRegCopyright, FaChartLine, FaTicketAlt, FaSpotify, FaExternalLinkSquareAlt } from 'react-icons/fa';
import Banner from '../assets/banner-alt.png';
import OfferDBPreview from '../assets/offer_db_preview.png';
import spacemanWave from '../assets/spacemanWave.png';
import AsteroidDust from '../assets/AsteroidDust.png';

function isValidEmailAddress(address) {
    console.log(address)
    return !! address.match(/.+@.+/);
}

export default function HomeScreen(props) {

    return (
        <section>
            <div style={{ width: '100%', height: '80vh', display: 'flex', flexDirection: props.width > 800 ? 'row' : 'column', alignItems: 'center', backgroundColor: '#000', justifyContent: 'center', marginTop: 50 }} id="section_top">
                <div style={{ width: props.width > 600 ? '50%' : '80%', marginTop: 5, height: 'auto', marginBottom: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Banner} width={"90%"} />
                </div>
                <div style={{ width: '50%', marginTop: 15, height: 'auto', marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ height: 300, width: 500 }}>
                        <h1 style={{ fontSize: 28, color: '#cdcdcd', fontWeight: 'bold' }}>Next Gen Music Booking Platform</h1>
                        <h1 style={{ fontSize: 18, color: '#cdcdcd' }}>Helping Agents and Artists Grow</h1>
                        <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', alignItems: props.width > 800 ? null : 'center' }}>
                            <div style={{ width: 300, display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                                <input style={{ width: 150, height: 32, backgroundColor: '#fff', color: '#000', fontSize: 12, borderRadius: 5 }} type="text" name="first_name" placeholder="First Name" value={props.first_name} onChange={props.handleChange} />
                                <input style={{ width: 150, height: 32, backgroundColor: '#fff', color: '#000', fontSize: 12, borderRadius: 5, marginLeft: 2 }} type="text" name="last_name" placeholder="Last Name" value={props.last_name} onChange={props.handleChange} />
                            </div>
                            <input style={{ width: 300, height: 32, backgroundColor: '#fff', color: '#000', fontSize: 12, borderRadius: 5, marginTop: 5 }} type="text" name="company" placeholder="Company" value={props.company} onChange={props.handleChange} />
                            <input style={{ width: 300, height: 32, backgroundColor: '#fff', color: '#000', fontSize: 12, borderRadius: 5, marginTop: 5 }} type="text" name="email" placeholder="Email" value={props.email} onChange={props.handleChange} 
                            onBlur={() => props.emailValidate(isValidEmailAddress(props.email))}
                            />
                            <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold', width: 200, marginTop: 25 }} className="btn btn-primary px-5" type="submit" onClick={props.requestSubmit}>Request a Demo</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{ height: 'auto', width: '100%', backgroundColor: '#111', display: 'flex', flexDirection: props.width > 800 ? 'row' : 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ marginLeft: '4%', width: '42%', marginRight: '4%', height: 800, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '100%', height: 'auto' }}>
                        <h1 style={{ fontSize: 28, color: '#cdcdcd', fontWeight: 'bold' }}>Fully automated database management tools</h1>
                        <h1 style={{ fontSize: 20, color: '#cdcdcd', fontWeight: 'bold' }}>to help you keep track and take control of your data</h1>
                    </div>
                    <div style={{ width: '100%', height: 'auto', marginTop: 30, display: 'flex' }}>
                        <div style={{ width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column' }}>

                        </div>
                        <div style={{ marginLeft: 15, width: 400, height: 300, borderRadius: 10 }}>
                            <ul>
                                <li style={{ color: '#cdcdcd', paddingRight: 10 }}>Quick view to see your upcoming shows, missing contracts / deposits, and missing final totals.</li>
                                <li style={{ color: '#cdcdcd', paddingRight: 10 }}>Automated emails to help you keep track of contracts, deposits, and finals.</li>
                                <li style={{ color: '#cdcdcd', paddingRight: 10 }}>Chat and task widget to keep you connected with other employees within your company.</li>
                            </ul>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: 'auto', position: 'absolute', marginTop: 550, marginLeft: 600 }}>
                        <img src={AsteroidDust} width="550" />
                    </div>
                </div>
                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 800 }}>
                    <div style={{ width: '90%', height: 'auto', paddingTop: 20, paddingLeft: '5%', paddingRight: '5%', paddingBottom: 15, backgroundColor: '#333', borderRadius: 10, textAlign: 'center' }}>
                        <div style={{ width: 'auto', height: 'auto', padding: 20, backgroundColor: '#303f9e', borderRadius: 10 }}>
                            <div style={{ width: 'auto', height: 'auto', padding: 10, backgroundColor: '#000', borderRadius: 10 }}>
                                <img src={OfferDBPreview} width="100%" />
                            </div>
                        </div>
                        <p style={{ fontSize: 12, color: '#fff', marginTop: 15, fontWeight: 'bold' }}>OFFER DATABASE</p>
                    </div>
                </div>
            </div> */}
            <div style={{ height: 'auto', width: '100%', backgroundColor: '#000', display: 'flex' }}>
                <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: props.width > 800 ? 'row' : 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <span style={{ height: 'auto', width: 200 }} className="btn btn-primary px-5"><FaChartLine style={{ marginRight: 10 }} size="40" color="#cdcdcd" /><br/><p style={{ fontSize: 12 }}>Show Analytics</p></span>
                    <span style={{ height: 'auto', width: 200 }} className="btn btn-primary px-5"><FaTicketAlt style={{ marginRight: 10 }} size="40" color="#cdcdcd" /><br/><p style={{ fontSize: 12 }}>Ticket Counts</p></span>
                    <span style={{ height: 'auto', width: 200 }} className="btn btn-primary px-5"><FaExternalLinkSquareAlt style={{ marginRight: 10 }} size="40" color="#cdcdcd" /><br/><p style={{ fontSize: 12 }}>Marketing Data</p></span>
                    <span style={{ height: 'auto', width: 200 }} className="btn btn-primary px-5"><FaSpotify style={{ marginRight: 10 }} size="40" color="#cdcdcd" /><br/><p style={{ fontSize: 12 }}>Artist Insights</p></span>
                </div>
                {/* <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 800 }}>

                </div> */}
            </div>
            <div style={{ height: 600, width: '100%', backgroundColor: '#000', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={spacemanWave} width="150" />
                <p style={{ fontSize: 20, color: '#fff', marginTop: 10, fontWeight: 'bold' }}>SEE YOU SOON!</p>
                <button style={{ fontSize: 12, color: '#F2F2F2', fontWeight: 'bold', width: 200 }} className="btn btn-primary px-5" type="button" onClick={() => window.location.replace("/#section_top")}>Request a Demo</button>
            </div>
            <div style={{ height: 'auto', width: '100%', backgroundColor: '#000', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ fontSize: 10, color: 'rgb(184, 184, 184)' }}><FaRegCopyright style={{ marginTop: -2 }} size="12" color="rgb(184, 184, 184)" /> 2021 Solar System, LLC</p>
            </div>
        </section >
    );
}